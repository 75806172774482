import { ErrorObj } from "data/const/Errors";
import { formattedTimeStamp } from "helpers/Tools";

export class ErrorMsg {
    date: string;
    error!: ErrorObj;
    data?: any;
    voids?: () => void;
    voidLabel?: string;
    voids2?: () => void;
    void2Label?: string;

    constructor(error: ErrorObj, data?:any, voidLabel?: string, voids?: () => void, void2Label?: string, voids2?: () => void) {
        this.date = formattedTimeStamp();
        this.error = error;
        this.data = data;
        this.voidLabel = voidLabel;
        this.voids = voids;
        this.void2Label = void2Label;
        this.voids2 = voids2;
    }
}
