import Config from "config/Config";
import {formattedTimeStamp} from "./Tools";

export default class LogHelper {

    private static logConsole(type: string, module:string, message: string, data?:any) {
        if(Config.debug == 0 && type == 'ERROR') {
            console.log(formattedTimeStamp() + ' [' + type + '] ' + module + ': ' + message, data);
        }else if(Config.debug >= 1){
            console.log(formattedTimeStamp() + ' [' + type + '] ' + module + ': ' + message, data);
        }
    }

    static logInfo(module: string, message: string) {
        this.logConsole('INFO', module, message);
    }
    static logWarn(module: string, message: string) {
        this.logConsole('WARN', module, message);
    }
    static logError(module: string, message: string, data?:any) {
        if(Config.debug) {
            console.warn('logError: ', message, data)
        }
        this.logConsole('ERROR', module, message, data);
    }
    static logDebug(module: string, message: string) {
        this.logConsole('DEBUG', module, message);
    }
}
