

export enum SrvErrors {
    CANNOT_CREATE_TOKEN = 'cannot_create_token',
    FORBIDDEN_NO_PLAYERID = 'forbidden_no_playerid',
    TOKEN_EXPIRED = 'token_expired',
    API_ACCESS_BANNED = 'api_access_banned',
    ACCOUNT_ALREADY_EXIST = 'account_already_exist',
    FORBIDDEN_KEY_WRONG = 'forbidden_key_wrong',
}


export enum CACHETIME {
    ONE_MINUTE = 60,
    TWO_MINUTE = 120
}

export enum RoomState {
    INITIALIZED = 0,
    CREATED = 1,
    OPEN = 2,
    PRIVATE_OPEN = 3,
    RUNNING = 4,
    DELAYED = 5,
    FINISHED = 6,
    FINISHED_COMPLETE = 7,
    CANCELED = 8,
}